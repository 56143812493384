export const defaultCashFlowStatement = {
  cashInFlow: {
    label: "Cash Inflow",
    you: 0,
    spouse: 0,
    combined: 0,
    monthly: 0,
    child: {
      grossAnnualEmploymentIncome: {
        label: "Gross Annual Employment Income",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      grossAnnualEmploymentBonus: {
        label: "Gross Annual Employment Bonus",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      epfFromEmployer: {
        label:
          "EPF from Employer (12% Income existing RM5,000 & 13% Income below RM 5,000)",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      subTotal: {
        label: "Sub Total",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      lessEpfOfEmployee: {
        label: "Less - EPF of Employee (11% Income)",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      lessEpfOfEmployer: {
        label: "Less - EPF of Employer",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      netAnnualIncomeAndBonus: {
        label: "Net Annual Income And Bonus",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      returnofInvestment: {
        label: "Return of Investment",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      otherIncome: {
        label: "Other Income",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
    },
  },
  cashOutFlow: {
    label: "Cash Outflow",
    you: 0,
    spouse: 0,
    combined: 0,
    monthly: 0,
    child: {
      homeUtilities: {
        label: "Home Utilities (e.g. Water, Gas, Paid TV License and others)",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      autoMaintenance: {
        label: "Auto Maintenance (services, road tax, petrol ",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      carLoanRepayment: {
        label: "Car Loan Repayment",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      creditCardRepayment: {
        label: "Credit Card Repayment",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      mortgageLoanRepayment: {
        label: "Mortgage Loan Repayment",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      otherLoanRepayment: {
        label: "Other Loan Repayment",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      savings: {
        label: "Savings",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      investment: {
        label:
          "Investment (e.g. Insurance premium / Takaful contribution, Investment / Islamic)",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      motorTakaful: {
        label: "Motor Takaful ",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      houseownerTakaful: {
        label: "Houseowner Takaful",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      quitRentAssessment: {
        label: "Quit Rent, Assessment",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      booksMagazinesNewspaper: {
        label: "Books, Magazines, Newspaper etc.",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      luxury: {
        label: "Luxury (e.g. Holiday, Clubs)",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      otherHobbiesInterest: {
        label: "Other Hobbies/Interest",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      familyPersonalLife: {
        label:
          "Family & Personal Life (e.g. Food, Clothing, Child Expenses, Medical)",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      parents: {
        label: "Parents",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      children: {
        label: "Children",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      taxZakat: {
        label: "Tax & Zakat Paid",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
      otherExpenses: {
        label: "Other Expenses",
        you: 0,
        spouse: 0,
        combined: 0,
        monthly: 0,
      },
    },
  },
  netCashFlow: {
    label: "Net Cash Flow",
    you: 0,
    spouse: 0,
    combined: 0,
    monthly: 0,
  },
};

export const defaultNetWorthStatement = {
  assets: {
    label: "Assets",
    you: 0,
    spouse: 0,
    combined: 0,
    child: {
      cashCashEquivalent: {
        label: "Cash / Cash Equivalent",
        you: 0,
        spouse: 0,
        combined: 0,
        child: {
          savingAccount: {
            label: "Savings Account",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          currentAccount: {
            label: "Current Account",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          takafulCashValue: {
            label: "Takaful Hayat Cash Value / Life Insurances Cash Value",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          tabungHaji: {
            label: "Tabung Haji",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          fixedDeposit: {
            label: "Fixed Deposit",
            you: 0,
            spouse: 0,
            combined: 0,
          },
        },
      },
      investmentAssets: {
        label: "Investment Assets",
        you: 0,
        spouse: 0,
        combined: 0,
        child: {
          equityShares: {
            label: "Equity (Shares)",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          fixedIncome: {
            label: "Bond/Fixed Income",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          unitTrustAsb: {
            label: "Unit Trust/ Islamic Unit Trust",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          investmentLinkedPolicies: {
            label: "Investment Linked Policies",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          educationallCashValue: {
            label: "Educational Plan Cash Value",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          propertyEgApartmentBungalow: {
            label: "Property eg. Apartment, Bungalow",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          epf: {
            label: "EPF",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          otherRetirementTools: {
            label: "Other Retirement Tools",
            you: 0,
            spouse: 0,
            combined: 0,
          },
        },
      },
      personalUsedAssets: {
        label: "Personal Used Assets",
        you: 0,
        spouse: 0,
        combined: 0,
        child: {
          ownerOccupiedResidentialPropertytLand: {
            label: "Owner Occupied Residential Property",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          cars: {
            label: "Cars ",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          homeFurnishings: {
            label: "Home Furnishings",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          jewelryCollections: {
            label: "Jewelry/Collections",
            you: 0,
            spouse: 0,
            combined: 0,
          },
          others: {
            label: "Others",
            you: 0,
            spouse: 0,
            combined: 0,
          },
        },
      },
    },
  },
  lblts: {
    label: "Liabilities",
    you: 0,
    spouse: 0,
    combined: 0,
    currentOS: 0,
    interestRate: 0,
    child: {
      currLblts: {
        label: "Current Liabilities",
        you: 0,
        spouse: 0,
        combined: 0,
        currentOS: 0,
        interestRate: 0,
        child: {
          creditCards: {
            label: "Credit Cards",
            you: 0,
            spouse: 0,
            combined: 0,
            currentOS: 0,
            interestRate: 0,
          },
          overdraft: {
            label: "Overdraft",
            you: 0,
            spouse: 0,
            combined: 0,
            currentOS: 0,
            interestRate: 0,
          },
          incomeTaxZakatPayable: {
            label: "Income Tax/Zakat Payable",
            you: 0,
            spouse: 0,
            combined: 0,
            currentOS: 0,
            interestRate: 0,
          },
          otherBankLoans: {
            label: "Other Bank Loans",
            you: 0,
            spouse: 0,
            combined: 0,
            currentOS: 0,
            interestRate: 0,
          },
        },
      },
      longTermLblts: {
        label: "Long Term Liabilities",
        you: 0,
        spouse: 0,
        combined: 0,
        currentOS: 0,
        interestRate: 0,
        child: {
          residentialHomeLoan: {
            label: "Residential Home Finance / Home Loan",
            you: 0,
            spouse: 0,
            combined: 0,
            currentOS: 0,
            interestRate: 0,
          },
          carHirePurchase: {
            label: "Car Hire Purchase",
            you: 0,
            spouse: 0,
            combined: 0,
            currentOS: 0,
            interestRate: 0,
          },
          investmentProperty: {
            label: "Investment Property",
            you: 0,
            spouse: 0,
            combined: 0,
            currentOS: 0,
            interestRate: 0,
          },
          others: {
            label: "Others",
            you: 0,
            spouse: 0,
            combined: 0,
            currentOS: 0,
            interestRate: 0,
          },
        },
      },
    },
  },
  networth: {
    label: "Networth",
    you: 0,
    spouse: 0,
    combined: 0,
  },
};

export const defaultGoals = {
  short_term: {
    goal_1: {
      goal: "",
      by_when: "",
      amount: "",
    },
    goal_2: {
      goal: "",
      by_when: "",
      amount: "",
    },
  },
  medium_term: {
    goal_1: {
      goal: "",
      by_when: "",
      amount: "",
    },
    goal_2: {
      goal: "",
      by_when: "",
      amount: "",
    },
  },
  long_term: {
    goal_1: {
      goal: "",
      by_when: "",
      amount: "",
    },
    goal_2: {
      goal: "",
      by_when: "",
      amount: "",
    },
  },
  notes: "",
};

export const defaultPersonalPriorities = [
  { priority: "Plan a budget", you: false, spouse: false },
  {
    priority:
      "Saving for short term plan (e.g. deposit for car or take a vacation)",
    you: false,
    spouse: false,
  },
  {
    priority: "Saving for medium term plan (e.g. deposit for house)",
    you: false,
    spouse: false,
  },
  {
    priority: "Create or update an investment plan",
    you: false,
    spouse: false,
  },
  { priority: "Analyze insurance needs", you: false, spouse: false },
  {
    priority: "Funding your children’s tertiary education",
    you: false,
    spouse: false,
  },
  { priority: "Review tax efficiencies", you: false, spouse: false },
  {
    priority: "Debt management / loan cancellation",
    you: false,
    spouse: false,
  },
  {
    priority: "Plan for a comfortable retirement",
    you: false,
    spouse: false,
  },
  {
    priority:
      "Create an estate plan to providing for family in the event of death ",
    you: false,
    spouse: false,
  },
  {
    priority:
      "Providing for you and your family in the event of disability",
    you: false,
    spouse: false,
  },
];

export const defaultAttitudesPreferences = {
  questions: [
    {
      question:
        "Are there any investments or companies you would avoid for ethical, social, or political reasons?",
      answer: "",
    },
    {
      question:
        "What investments have you made in the past that you have been pleased with? Why?",
      answer: "",
    },
    {
      question: "What past investments have not pleased you? Why?",
      answer: "",
    },
    {
      question:
        "Have you ever sold an investment when its price was down and later regretted doing so? What was it?",
      answer: "",
    },
    {
      question:
        "Have you experienced losses during an extended (one year +) bear market? When and how much?I would be uncomfortable if my investment income varied substantially from month to month.",
      answer: "",
    },
  ],
  notes: "",
};

export const defaultHistoryExperiences = {
  statements: [
    {
      statement:
        "I am uncomfortable with any significant fluctuation in my portfolio.",
      is_agree: false,
    },
    { statement: "I monitor my investments frequently.", is_agree: false },
    {
      statement:
        "It's important that my investments grow faster than inflation, even if my investments aren't 100% safe.",
      is_agree: false,
    },
    {
      statement:
        "I prefer mutual funds or exchange-traded funds to individual stocks or bonds.",
      is_agree: false,
    },
    {
      statement: "I prefer investing in large, stable companies.",
      is_agree: false,
    },
    {
      statement:
        "I would be uncomfortable if my investment income varied substantially from month to month.",
      is_agree: false,
    },
    {
      statement: "I feel comfortable with aggressive growth investments.",
      is_agree: false,
    },
    { statement: "I consider myself a risk taker.", is_agree: false },
    {
      statement: "I would describe myself as a 'buy and hold' investor.",
      is_agree: false,
    },
    {
      statement:
        "In my household, I am the sole decision-maker about investments.",
      is_agree: false,
    },
    {
      statement:
        "I prefer to invest more conservatively than my spouse/partner.",
      is_agree: false,
    },
    {
      statement: "I feel comfortable using leverage when investing.",
      is_agree: false,
    },
    {
      statement:
        "My family has no difficulty maintaining a financial cushion for emergencies.",
      is_agree: false,
    },
    {
      statement: "I am optimistic about the world economy.",
      is_agree: false,
    },
    {
      statement:
        "I use dollar-cost averaging (regularly investing equal amounts of money in a single investment).",
      is_agree: false,
    },
  ],
  notes: "",
};

export const defaultRiskProfilQuestionnaire = {
  questions: [
    {
      question:
        "How long would you consider investing to achieve your financial goals? These goals could include children’s education, retirement planning or others like car, house, marriage, etc) is?",
      options: [
        { value: "a", label: "Less than 3 year" },
        { value: "b", label: "5 - 10 years" },
        { value: "c", label: "More than 10 years" },
      ],
      answer: "",
    },
    {
      question:
        "What sort of investor are you with respect to investment risk versus return?",
      options: [
        {
          value: "a",
          label:
            "Willing to accept minimal amount of risk for small capital growth.",
        },
        {
          value: "b",
          label:
            "Willing to take moderate risk to achieve long-term capital growth",
        },
        {
          value: "c",
          label:
            "Willing to take high risk to achieve high capital growth and returns",
        },
      ],
      answer: "",
    },
    {
      question:
        "If the value of your investment portfolio increase by 25%, which of the following actions will you take?",
      options: [
        {
          value: "a",
          label: "Dispose all the investment in the portfolio",
        },
        {
          value: "b",
          label:
            "Divest and withdraw some cash from the investment portfolio",
        },
        {
          value: "c",
          label:
            "Maintain status quo or increase investment in the portfolio",
        },
      ],
      answer: "",
    },
    {
      question:
        "Currently, how much funds have you allocate for savings to cope with any occurrence of emergency?",
      options: [
        { value: "a", label: "More than 6 months living expenses" },
        { value: "b", label: "Between 3 to 6 months living expenses" },
        { value: "c", label: "Not more than 3 months living expenses" },
      ],
      answer: "",
    },
    {
      question:
        "Which of the following statement would you choose with regards to having higher security and higher returns against inflation that may erode the value of your investment? ",
      options: [
        {
          value: "a",
          label:
            "Security of my savings is utmost important regardless whether value of the investment is eroded by inflation",
        },
        {
          value: "b",
          label:
            "The return and value of investment must increase in the same rate as the inflation rate and not eroded by inflation",
        },
        {
          value: "c",
          label:
            "The return and value of investment must increase higher than inflation rate and not eroded by inflation",
        },
      ],
      answer: "",
    },
    {
      question:
        "What is the maximum amount of loss that you are prepare to tolerate in your investment portfolio which was invested one year ago?",
      options: [
        { value: "a", label: "0%" },
        { value: "b", label: "10%" },
        { value: "c", label: "25%" },
      ],
      answer: "",
    },
  ],
  total_Score: 0,
};

export const defaultEconomicIndexes = [
  {
    question: "What do you feel is a fair rate of inflation?",
    you: "",
    spouse: "",
  },
  {
    question: "What is your expected wage (including bonus) growth rate?",
    you: "",
    spouse: "",
  },
  {
    question:
      "Either: What % of your current annual income would you need at retirement?",
    you: "",
    spouse: "",
  },
  // {
  //   question:
  //     "Or: What annual amount, in today’s Ringgit would you require during retirement?",
  //   you: "",
  //   spouse: "",
  // },
  {
    question: "What would you consider a fair rate of return for the ",
    you: "",
    spouse: "",
    subquestion: [
      { question: "Following before retirement?", you: "", spouse: "" },
      { question: "Savings/Current Account", you: "", spouse: "" },
      { question: "Fixed Deposit", you: "", spouse: "" },
      { question: "Traditional Insurance Polices", you: "", spouse: "" },
      {
        question: "Investment-linked Insurance Policies",
        you: "",
        spouse: "",
      },
      { question: "Stocks/Shares", you: "", spouse: "" },
      { question: "Unit Trusts", you: "", spouse: "" },
      { question: "Real Estate", you: "", spouse: "" },
      { question: "EPF", you: "", spouse: "" },
      { question: "Other Retirement Account", you: "", spouse: "" },
    ],
  },
  {
    question:
      "What would you consider a fair rate of return on investment during retirement?",
    you: "",
    spouse: "",
  },
  {
    question: "What would you consider a fair education inflation rate?",
    you: "",
    spouse: "",
  },
];

export const defaultInsuranceTakaful = [
  {
    type: "Health",
    client: "",
    spouse: "",
  },
  {
    type: "Disability",
    client: "",
    spouse: "",
  },
  {
    type: "Life",
    client: "",
    spouse: "",
  },
  {
    type: "Homeowners or Renters",
    client: "",
    spouse: "",
  },
  {
    type: "Auto",
    client: "",
    spouse: "",
  },
  {
    type: "Long-Term Care",
    client: "",
    spouse: "",
  },
  {
    type: "Personal Umbrella Liability",
    client: "",
    spouse: "",
  },
  {
    type: "Business Owner",
    client: "",
    spouse: "",
  },
  {
    type: "Business Liability",
    client: "",
    spouse: "",
  },
  {
    type: "Other (specify below)",
    client: "",
    spouse: "",
  },
];
