
import { defineComponent, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defaultAttitudesPreferences } from "../../../utils/FactFindingSheetUtils";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  name: "customer-financial-statements-cash-flow",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      attitudes_preferences: cloneDeep(defaultAttitudesPreferences),
    };
  },
  methods:{
    onResetAttitudesPreferences() {
      Swal.fire({
        title: "Are you sure?",
        text:
          "You will not be able to recover this Investment Attitudes and Preferences details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.attitudes_preferences = cloneDeep(defaultAttitudesPreferences);
          Swal.fire({
            title: "Reset!",
            text: "Investment Attitudes and Preferences details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },

    onSaveAttitudesPreferences() {
      this.$emit("onSaveFactFindingSheet", "Investment Attitudes And Preferences",  this.attitudes_preferences);
    },
  }
});
