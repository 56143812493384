
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { Modal } from "bootstrap";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter, useRoute } from "vue-router";

import {
  defaultProfile,
  defaultParent,
  defaultExecutors,
  defaultTrustees,
} from "../../../utils/ProfileUtils";

import {
  genderOptions,
  maritalStatusOptions,
  residentialStatusOptions,
  educationOptions,
  employmentOptions,
} from "../../../../data/FASLookups";

import PersonalInformation from "./PersonalInformation.vue";
import SpouseInformation from "./SpouseInformation.vue";
import ChildrenInformation from "./ChildrenInformation.vue";
import CashFlowStatement from "./CashFlowStatement.vue";
import CashFlowQuestionnaire from "./CashFlowQuestionnaire.vue";
import NetWorthStatement from "./NetWorthStatement.vue";
import NetWorthQuestionnaire from "./NetWorthQuestionnaire.vue";
import GoalsAndObjectives from "./GoalsAndObjectives.vue";
import PersonalPriorities from "./PersonalPriorities.vue";
import InvestmentPlanningAttitudesAndPreferences from "./InvestmentPlanningAttitudesAndPreferences.vue";
import InvestmentPlanningHistoryExperiences from "./InvestmentPlanningHistoryExperiences.vue";
import RiskProfile from "./RiskProfile.vue";
import RetirementPlanning from "./RetirementPlanning.vue";
import GeneralInformation from "./GeneralInformation.vue";
import EconomicIndex from "./EconomicIndex.vue";
import TertiaryPlanning from "./TertiaryPlanning.vue";
import Others from "./Others.vue";
import ExistingInsuranceTakafulPolicies from "./ExistingInsuranceTakafulPolicies.vue";

export default defineComponent({
  name: "calculators",
  components: {
    Field,
    PersonalInformation,
    SpouseInformation,
    ChildrenInformation,
    CashFlowStatement,
    CashFlowQuestionnaire,
    NetWorthStatement,
    NetWorthQuestionnaire,
    GoalsAndObjectives,
    PersonalPriorities,
    InvestmentPlanningAttitudesAndPreferences,
    InvestmentPlanningHistoryExperiences,
    RiskProfile,
    RetirementPlanning,
    GeneralInformation,
    EconomicIndex,
    TertiaryPlanning,
    Others,
    ExistingInsuranceTakafulPolicies,
  },
  setup() {
    onMounted(() => {
      // setCurrentPageBreadcrumbs("Will Planning", ["Financial Planning"]);
    });

    const { n } = useI18n();

    return {
      n,
    };
  },
  data() {
    const customerNRICToSearch = "831023045007";
    const factFindingSheets = [];
    const factFindingSheet = {};
    const showFactFindingSheet = false;
    const route = useRoute();
    const router = useRouter();
    const cstmr_id = route.params.id ?? null;
    const path = route.path;
    const isMuslim = false;

    const profile = { ...defaultProfile };
    const spouses = [];
    const children = [];
    const ffs = {
      cashFlow: {
        statement: null,
        questionnaire: null,
      },
      netWorth: {
        statement: null,
        questionnaire: null,
      },
      goalsAndObjectives: null,
      personalPriorities: null,
      investmentPlanning: {
        attitudesAndPreferences: null,
        historyAndExperiences: null,
      },
      riskProfile: null,
      retirementPlanning: null,
      tertiaryPlanning: null,
      others: null,
      economicIndex: null,
      existingInsuranceTakafulPolicies: null,
      generalInformation: null,
    };
    const activeName = ref("");
    const activeNameCashFlow = ref("");
    const activeNameNetWorth = ref("");
    const activeFinancialGoal = ref("");

    const states = [];
    const races = [];
    const pCities = [];
    const sCities = [];
    const spCities = [];
    const family = {
      id: null,
      user_id: null,
      type: "family",
      raw: {} as any,
    };

    //Lookup
    const genders = genderOptions;
    const residential_statuses = residentialStatusOptions;

    return {
      store: useStore(),
      cstmr_id,
      customerNRICToSearch,
      showFactFindingSheet,
      factFindingSheets,
      factFindingSheet,
      active: 1,
      activeNameCashFlow,
      activeNameNetWorth,
      activeFinancialGoal,
      activeName,
      profile,
      children,
      genders,
      states,
      spouses,
      family,
      races,
      pCities,
      sCities,
      spCities,
      isMuslim,
      residential_statuses,
      ffs,
      router,
      path,
    };
  },
  methods: {
    init() {
      if (!this.cstmr_id) {
        // this.searchCustomerByNRIC();
        this.showSearchCustomerModal();
      } else this.getFactFindingSheet(this.cstmr_id);

      this.store.dispatch(Actions.GET_STATES).then(() => {
        this.states = this.store.getters.getStatesData;
      });

      this.store.dispatch(Actions.GET_RACES).then(() => {
        this.races = this.store.getters.getRacesData;
      });
    },
    handleChange(val) {
      // console.log(val);
    },
    showSearchCustomerModal() {
      new Modal("#searchCustomerModal", {
        backdrop: "static",
        keyboard: false,
      }).show();
    },
    searchCustomerByNRIC() {
      this.showSwalLoading("Fetching Customer Data");
      this.store
        .dispatch(Actions.GET_CUSTOMER_BY_NRIC, this.customerNRICToSearch)
        .then(() => {
          let theProfile = this.store.getters.getCustomerData;
          if (theProfile.id) {
            this.cstmr_id = theProfile.id;
            setTimeout(() => {
              this.redirecting();
            }, 1000);
          } else {
            this.showSwalResult("Customer not found", "error");
          }
        });
    },
    redirecting() {
      this.showSwalLoading("Redirecting");
      setTimeout(() => {
        this.$router
          .push({
            path:
              "/customers/details/" +
              this.cstmr_id +
              "/financial-planning/fact-finding-sheet",
          })
          .then(() => {
            window.location.reload();
          });
      }, 1000);
    },
    getFactFindingSheet(user_id) {
      this.showSwalLoading("Populating Data");
      this.store.dispatch(Actions.GET_WILL_PLANNINGS, user_id).then(() => {
        this.factFindingSheets = this.store.getters.getFactFindingSheetsData;
        // if(this.factFindingSheets.length > 0) {

        // } else {
        //   this.showSwalResult("Customer not found")
        // }
        this.getUserProfile(user_id);
      });
    },
    getUserProfile(user_id) {
      this.store.dispatch(Actions.GET_USER_BASIC_PROFILE, user_id).then(() => {
        this.profile = this.store.getters.getCustomerBasicProfileData;
        this.isMuslim = this.profile.user_raw_profile?.raw?.is_muslim ?? false;
        setTimeout(() => {
          this.getFamilyProfile();
        }, 1000);
      });
    },
    getFamilyProfile() {
      this.store
        .dispatch(Actions.GET_USER_FAMILY_PROFILE, this.profile.id)
        .then(() => {
          let family = this.store.getters.getFamilyProfileData.user_raw_family;
          if (family !== null) {
            this.family = family;
            this.spouses = this.family.raw.spouses;
            this.children = this.family.raw.children;
          }
          setTimeout(() => {
            this.getFactFindingSheetProfile();
          }, 500);
        });
    },
    getFactFindingSheetProfile() {
      this.store
        .dispatch(Actions.GET_USER_FFS_PROFILE, this.profile.id)
        .then(() => {
          const ffs = this.store.getters.getFactFindingSheetProfileData;
          if (ffs.user_raw_ffs?.raw) {
            this.ffs = ffs.user_raw_ffs.raw;
          }
          setTimeout(() => {
            this.showFactFindingSheet = true;
            Swal.close();
          }, 500);
        });
    },
    getCities(data) {
      this.store
        .dispatch(Actions.GET_CITIES, this.getStateId(data.state))
        .then(() => {
          const typeToCitiesMap = {
            spouse: "spCities",
            personal: "pCities",
          };

          const property = typeToCitiesMap[data.type];
          if (property) {
            this[property] = this.store.getters.getCitiesData;
          }
        });
    },
    getStateId(state_name) {
      for (let index of Object.keys(this.states)) {
        if (state_name == this.states[index].ParameterValue) {
          return this.states[index].id;
        }
      }
    },
    showSwalLoading(message) {
      Swal.fire({
        title: message,
        text: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    },
    showSwalResult(message, icon = "success") {
      Swal.fire({
        icon: icon,
        title: message,
        text: "Do you want to proceed?",
        allowOutsideClick: false,
      }).then((result) => {
        if (message == "Customer not found") {
          this.showSearchCustomerModal();
        }
      });
    },
    toCamelCase(str) {
      return str
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
        .replace(/^./, (chr) => chr.toLowerCase());
    },
    onSaveFactFindingSheet(type, data = null) {
      this.isMuslim = this.profile.user_raw_profile.raw.is_muslim ?? false;

      Swal.fire({
        title: "Saving " + type + " Information",
        text: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      setTimeout(() => {
        switch (type) {
          case "Personal":
            this.saveProfile(type);
            break;
          case "Spouses":
            this.spouses = this.profile.user_raw_spouses.raw;
            this.saveProfile(type);
            break;
          case "Children":
            this.saveRaw(type, "family");
            break;
          case "Cash Flow Statement":
            this.ffs.cashFlow.statement = data;
            this.saveRaw(type, "ffs");
            break;
          case "Cash Flow Questionnaire":
            this.ffs.cashFlow.questionnaire = data;
            this.saveRaw(type, "ffs");
            break;
          case "Net Worth Statement":
            this.ffs.netWorth.statement = data;
            this.saveRaw(type, "ffs");
            break;
          case "Net Worth Questionnaire":
            this.ffs.netWorth.questionnaire = data;
            this.saveRaw(type, "ffs");
            break;
          case "Goals And Objectives":
            this.ffs.goalsAndObjectives = data;
            this.saveRaw(type, "ffs");
            break;
          case "Personal Priorities":
            this.ffs.personalPriorities = data;
            this.saveRaw(type, "ffs");
            break;
          case "Investment Attitudes And Preferences":
            this.ffs.investmentPlanning.attitudesAndPreferences = data;
            this.saveRaw(type, "ffs");
            break;
          case "Investment History And Experiences":
            this.ffs.investmentPlanning.historyAndExperiences = data;
            this.saveRaw(type, "ffs");
            break;
          case "Risk Profile":
            this.ffs.riskProfile = data;
            this.saveRaw(type, "ffs");
            break;
          case "Retirement Planning":
            this.ffs.retirementPlanning = data;
            this.saveProfile(type);
            this.saveRaw(type, "ffs");
            break;
          case "Tertiary Planning":
            this.ffs.tertiaryPlanning = data;
            this.saveRaw(type, "ffs");
            this.saveRaw(type, "family");
            break;
          case "Others":
            this.ffs.others = data;
            this.saveRaw(type, "ffs");
            break;
          case "Economic Index":
            this.ffs.economicIndex = data;
            this.saveRaw(type, "ffs");
            break;
          case "Existing Insurance Takaful Policies":
            this.ffs.existingInsuranceTakafulPolicies = data;
            this.saveRaw(type, "ffs");
            break;
          case "General Information":
            this.ffs.generalInformation = data;
            this.saveRaw(type, "ffs");
            break;
        }
      }, 1500);
    },
    saveProfile(type) {
      this.store.dispatch(Actions.UPDATE_PROFILE, this.profile).then(() => {
        if (type == "Spouses") {
          this.saveRaw(type, "family");
        } else {
          Swal.fire({
            icon: "success",
            title: "Personal Information has been saved",
            allowOutsideClick: false,
          });
        }
      });
    },
    saveRaw(saved, type) {
      let raw = {};

      if (type == "family") {
        raw = {
          ...this.family.raw,
          spouses: this.spouses,
          children: this.children,
        };
      }

      if (type == "ffs") {
        raw = this.ffs;
      }

      let data = {
        type: type,
        user_id: this.profile.id,
        raw: raw,
      };

      this.store.dispatch(Actions.STORE_RAW, data).then(() => {
        this.showSwalResult("Your " + saved + " Information has been saved");
      });
    },
    submitFactFindingSheet() {
      this.showSwalLoading("Saving Fact Finding Sheet");

      setTimeout(() => {
        const data = {
          user_id: this.cstmr_id,
          type: "ffs",
          raw: this.ffs,
        };
        // this.store.dispatch(Actions.STORE_FFS_PLANNING, data).then(() => {
        //   this.showSwalResult("Tax Planning has been saved");
        // });
        this.showSwalResult("Fact Finding Sheet has been saved");
      }, 1500);
    },
    goToCard(card) {
      this.router.push({ path: this.path, hash: card });
    },
  },
  async mounted() {
    this.init();
  },
});
