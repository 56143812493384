
import { defineComponent, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import { defaultGoals } from "../../../utils/FactFindingSheetUtils";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  name: "customer-financial-statements-cash-flow",
  components: {
    Form,
    Field,
    // ErrorMessage,
    ElCurrencyInputNoSymbol,
  },
  data() {
    return {
      goals: cloneDeep(defaultGoals),
      showDoc:true
    };
  },
  methods:{
    onResetGoalsAndObjectives() {
      this.showDoc = false;
      Swal.fire({
        title: "Are you sure?",
        text:
          "You will not be able to recover this Goals And Objectives details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.goals = cloneDeep(defaultGoals);
          this.showDoc = true;
          Swal.fire({
            title: "Reset!",
            text: "Goals And Objectives details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },

    onSaveGoalsAndObjectives() {
      this.$emit("onSaveFactFindingSheet", "Goals And Objectives", this.goals);
    },
  }
});
