
import { defineComponent, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defaultRiskProfilQuestionnaire } from "../../../utils/FactFindingSheetUtils";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  name: "customer-financial-statements-cash-flow",
  components: {
    Form,
    Field,
  },
  data() {
    const risk_profil_questionnaire = {
      questions: [
        {
          question:
            "How long would you consider investing to achieve your financial goals? These goals could include children’s education, retirement planning or others like car, house, marriage, etc) is?",
          options: [
            { value: "a", label: "Less than 3 year" },
            { value: "b", label: "5 - 10 years" },
            { value: "c", label: "More than 10 years" },
          ],
          answer: "",
        },
        {
          question:
            "What sort of investor are you with respect to investment risk versus return?",
          options: [
            {
              value: "a",
              label:
                "Willing to accept minimal amount of risk for small capital growth.",
            },
            {
              value: "b",
              label:
                "Willing to take moderate risk to achieve long-term capital growth",
            },
            {
              value: "c",
              label:
                "Willing to take high risk to achieve high capital growth and returns",
            },
          ],
          answer: "",
        },
        {
          question:
            "If the value of your investment portfolio increase by 25%, which of the following actions will you take?",
          options: [
            {
              value: "a",
              label: "Dispose all the investment in the portfolio",
            },
            {
              value: "b",
              label:
                "Divest and withdraw some cash from the investment portfolio",
            },
            {
              value: "c",
              label:
                "Maintain status quo or increase investment in the portfolio",
            },
          ],
          answer: "",
        },
        {
          question:
            "Currently, how much funds have you allocate for savings to cope with any occurrence of emergency?",
          options: [
            { value: "a", label: "More than 6 months living expenses" },
            { value: "b", label: "Between 3 to 6 months living expenses" },
            { value: "c", label: "Not more than 3 months living expenses" },
          ],
          answer: "",
        },
        {
          question:
            "Which of the following statement would you choose with regards to having higher security and higher returns against inflation that may erode the value of your investment? ",
          options: [
            {
              value: "a",
              label:
                "Security of my savings is utmost important regardless whether value of the investment is eroded by inflation",
            },
            {
              value: "b",
              label:
                "The return and value of investment must increase in the same rate as the inflation rate and not eroded by inflation",
            },
            {
              value: "c",
              label:
                "The return and value of investment must increase higher than inflation rate and not eroded by inflation",
            },
          ],
          answer: "",
        },
        {
          question:
            "What is the maximum amount of loss that you are prepare to tolerate in your investment portfolio which was invested one year ago?",
          options: [
            { value: "a", label: "0%" },
            { value: "b", label: "10%" },
            { value: "c", label: "25%" },
          ],
          answer: "",
        },
      ],
      total_Score: 0,
    };
    const investorProfile = "";
    return {
      risk_profil_questionnaire: cloneDeep(defaultRiskProfilQuestionnaire),
      investorProfile,
    };
  },
  methods: {
    calculateRisk() {
      let totalScore = 0;
      this.risk_profil_questionnaire.questions.forEach((element) => {
        if (element.answer == "a") {
          totalScore += 1;
        } else if (element.answer == "b") {
          totalScore += 2;
        } else if (element.answer == "c") {
          totalScore += 3;
        }
      });
      this.risk_profil_questionnaire.total_Score = totalScore;
      if (totalScore <= 10 && totalScore >= 6) {
        this.investorProfile = "Low Risks";
      } else if (totalScore <= 15 && totalScore >= 11) {
        this.investorProfile = "Medium Risks";
      } else if (totalScore <= 18 && totalScore >= 16) {
        this.investorProfile = "High Risks";
      }
    },
    onResetRiskProfile() {
      Swal.fire({
        title: "Are you sure?",
        text:
          "You will not be able to recover this Risk Profile details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.risk_profil_questionnaire = cloneDeep(defaultRiskProfilQuestionnaire);
          this.investorProfile = "";
          Swal.fire({
            title: "Reset!",
            text: "Risk Profile details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },

    onSaveRiskProfile() {
      this.$emit("onSaveFactFindingSheet", "Risk Profile", this.risk_profil_questionnaire);
    },
  },
});
