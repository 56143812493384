
import { defineComponent, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "customer-financial-statements-cash-flow",
  props: [""],
  components: {
    Form,
    Field,
  },
  data() {
    const general_information = "";
    return {
      general_information,
    };
  },
  methods:{
    onResetGeneralInformation() {
      Swal.fire({
        title: "Are you sure?",
        text:
          "You will not be able to recover this General Information details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.general_information = "";

          Swal.fire({
            title: "Reset!",
            text: "General Information details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },

    onSaveGeneralInformation() {
      this.$emit("onSaveFactFindingSheet", "General Information", this.general_information);
    },
  },
  async mounted() {
    // this.init();
  },
});
