
import { defineComponent, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defaultPersonalPriorities } from "../../../utils/FactFindingSheetUtils";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  name: "customer-financial-statements-cash-flow",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      personal_priorities: cloneDeep(defaultPersonalPriorities),
    };
  },
  methods:{
    onResetPersonalPriorities() {
      Swal.fire({
        title: "Are you sure?",
        text:
          "You will not be able to recover this Personal Priorities details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.personal_priorities = cloneDeep(defaultPersonalPriorities);
          Swal.fire({
            title: "Reset!",
            text: "Personal Priorities details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },

    onSavePersonalPriorities() {
      this.$emit("onSaveFactFindingSheet", "Personal Priorities");
    },
  }
});
