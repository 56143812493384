
import { defineComponent, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defaultInsuranceTakaful } from "../../../utils/FactFindingSheetUtils";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  name: "customer-financial-statements-cash-flow",
  props: [""],
  components: {
    Form,
    Field,
  },
  data() {
    const questions = "";
    return {
      existing_insurance_takaful: cloneDeep(defaultInsuranceTakaful),
      questions,
      existing_insurance_takaful_data: {},
    };
  },
  methods: {
    onResetPolicies() {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Existing Insurance Takaful Policies details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.existing_insurance_takaful = cloneDeep(defaultInsuranceTakaful);
          this.questions = "";
          Swal.fire({
            title: "Reset!",
            text: "Existing Insurance Takaful Policies details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },

    onSavePolicies() {
      this.existing_insurance_takaful_data = {
        existing_insurance_takaful: this.existing_insurance_takaful,
        questions: this.questions,
      };
      this.$emit("onSaveFactFindingSheet", "Existing Insurance Takaful Policies", this.existing_insurance_takaful_data);
    },
  },
  async mounted() {
    // this.init();
  },
});
