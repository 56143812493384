
import { defineComponent, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "customer-financial-statements-cash-flow",
  props:['theFFS'],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const cash_flow_questionnaire = {
      q1: "",
      q2: "",
    };
    return {
      cash_flow_questionnaire,
      isCashFlowLoaded: false,
    };
  },
  methods: {
    init() {
      if (this.theFFS.cashFlow.questionnaire) {
        this.cash_flow_questionnaire = this.theFFS.cashFlow.questionnaire;
      }
      this.isCashFlowLoaded = true;
    },
    onSaveCashFlowQuestionnaire() {
      this.$emit(
        "onSaveFactFindingSheet",
        "Cash Flow Questionnaire",
        this.cash_flow_questionnaire
      );
    },

    onResetCashFlowQuestionnaire() {
      Swal.fire({
        title: "Are you sure?",
        text:
          "You will not be able to recover this Cash Flow Questionnaire details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.cash_flow_questionnaire.q1 = "";
          this.cash_flow_questionnaire.q2 = "";
          Swal.fire({
            title: "Reset!",
            text: "Cash Flow Questionnaire details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
  },
  async mounted() {
    this.init();
  },
});
