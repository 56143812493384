
import { defineComponent, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { defaultProfile } from "../../../utils/ProfileUtils";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "customer-financial-statements-cash-flow",
  props: ["theProfile"],
  components: {
    Form,
    Field,
  },
  data() {
    const profile = {} as any;
    const spouse = {
      retirement_age: 0,
      life_expectancy: 0,
    };
    return {
      profile,
      spouse,
      isDataReady: false,
      retirementPlanning: {},
    };
  },
  methods: {
    init() {
      setTimeout(() => {

        this.profile = { ...defaultProfile, ...this.theProfile }
        this.profile.user_raw_profile.raw.retirement_age =
          this.profile.user_raw_profile.raw?.retirement_age ?? 0;
        this.profile.user_raw_profile.raw.life_expectancy =
          this.profile.user_raw_profile.raw?.life_expectancy ?? 0;
        // console.log(this.theProfile);
        this.isDataReady = true;
      }, 500);
    },
    onResetRetirementPlanning() {
      Swal.fire({
        title: "Are you sure?",
        text:
          "You will not be able to recover this Retirement Planning details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.profile.user_raw_profile.raw.retirement_age = 0;
          this.profile.user_raw_profile.raw.life_expectancy = 0;
          this.spouse.retirement_age = 0;
          this.spouse.life_expectancy = 0;

          Swal.fire({
            title: "Reset!",
            text: "Retirement Planning details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },

    onSaveRetirementPlanning() {
      this.retirementPlanning = {
        profile: {
          retirement_age: this.profile.user_raw_profile.raw.retirement_age,
              life_expectancy: this.profile.user_raw_profile.raw
                .life_expectancy,
        },
        spouse: {
          retirement_age: this.spouse.retirement_age,
          life_expectancy: this.spouse.life_expectancy,
        },
      };
      this.$emit("onSaveFactFindingSheet", "Retirement Planning", this.retirementPlanning);
    },
  },
  async mounted() {
    this.init();
  },
});
