
import { defineComponent, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "cusofmer-financial-statements-cash-flow",
  props: ["theChildren"],
  components: {
    Form,
    Field,
    ElCurrencyInput,
  },
  data() {
    const children = [];
    return {
      children,
      showDoc: true,
    };
  },
  methods: {
    onResetTertiaryPlanning() {
      this.showDoc = false;
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Tertiary Planning details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          for (let i = 0; i < this.children.length; i++) {
            this.children[i].education_cost = 0;
            this.children[i].years_to_study = 0;
            this.children[i].is_local = false;
            this.children[i].years_of_study = 0;
            this.children[i].available_resources = 0;
          }
          this.showDoc = true
          Swal.fire({
            title: "Reset!",
            text: "Tertiary Planning details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },

    onSaveTertiaryPlanning() {
      this.$emit("onSaveFactFindingSheet", "Tertiary Planning", this.children);
    },
  },
  async mounted() {
    this.children = {...this.theChildren};
    // this.init();
  },
});
