
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import moment from "moment";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "update-personal",
  props: [
    "theProfile",
    "userBanks",
    "genders",
    "states",
    "races",
    "pCities",
    "residential_statuses",
  ],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const addSpouseModal = ref(null);
    const closeModal = () => Modal.getInstance(addSpouseModal.value)?.hide();
    return {
      addSpouseModal,
      closeModal,
    };
  },
  data() {
    const profileDetailsValidator = Yup.object({
      profile: Yup.object({
        name: Yup.string().required().label("Name"),
        nric: Yup.string().required().label("NRIC"),
        email: Yup.string().email().required().label("Email"),
        phone: Yup.number()
          .required()
          .label("Phone No")
          .test(
            "minDigits",
            "Please enter a valid Phone No. eg: 0123456789",
            (number) => String(number).length >= 8
          )
          .typeError("Please enter a valid Phone No. eg: 0123456789"),
        gender: Yup.string().required().label("Gender"),
        birthdate: Yup.string()
          .required()
          .label("Birthdate")
          .typeError("Please select date."),
        user_raw_profile: Yup.object({
          raw: Yup.object({
            race: Yup.string().required().label("Race"),
            employment: Yup.string().required().label("Employment"),
            education: Yup.string().required().label("Education"),
            alt_phone: Yup.number()
              .required()
              .label("Alternative Contact Person No")
              .test(
                "minDigits",
                "Please enter a valid Phone No. eg: 0123456789",
                (number) => String(number).length >= 8
              )
              .typeError("Please enter a valid Phone No. eg: 0123456789"),
            address1: Yup.string().required().label("Address Line 1"),
            address2: Yup.string().required().label("Address Line 2"),
            state: Yup.string().required().label("State"),
            city: Yup.string().required().label("City"),
            postcode: Yup.number()
              .required()
              .label("Postcode")
              .typeError("Please enter a valid Postcode eg: 12345"),
          }),
        }),
      }),
    });

    const employmentStatuses = [
      {
        value: "Employed",
        label: "Employed",
      },
      {
        value: "Unemployed",
        label: "Unemployed",
      },
    ];

    const employments = [
      {
        value: "Government",
        label: "Government",
      },
      {
        value: "Private",
        label: "Private",
      },
      {
        value: "Retiree",
        label: "Retiree",
      },
      {
        value: "Self Employed",
        label: "Self Employed",
      },
      {
        value: "Unemployed",
        label: "Unemployed",
      },
    ];

    const educations = [
      {
        value: "None",
        label: "None",
      },
      {
        value: "School (Primary/Secondary)",
        label: "School (Primary/Secondary)",
      },
      {
        value: "Undergraduate (Certificate/Dimploma/Degree)",
        label: "Undergraduate (Certificate/Dimploma/Degree)",
      },
      {
        value: "Postgraduate/Profesional",
        label: "Postgraduate/Profesional",
      },
    ];

    const store = useStore();

    const profile = {
      id: null,
      name: "",
      nric: "",
      email: "",
      phone: "",
      gender: "",
      birthdate: "",
      user_raw_profile: {
        id: null,
        user_id: null,
        type: "profile",
        raw: {
          address1: "",
          address2: "",
          address3: "",
          alt_phone: "",
          birthdate: "",
          city: "",
          education: "",
          email: "",
          employment: "",
          employment_address: "",
          employment_name: "",
          gender: "",
          have_living_trust_setup: false,
          have_power_of_attorney: false,
          have_testamentary_trust_setup: false,
          id: null,
          is_good_health: false,
          is_muslim: false,
          is_smoker: false,
          life_expectancy: "",
          marital_status: "",
          medical_hereditary: "",
          name: "",
          name_of_attorney: "",
          no_of_dependent: "",
          nric: "",
          occupation: "",
          phone: "",
          postcode: "",
          race: "",
          residential_status: "",
          retirement_age: "",
          retirement_date: "",
          state: "",
          will_held_by: "",
          will_last_updated: "",
        },
      },
      user_raw_spouses: {
        id: null,
        user_id: null,
        type: "spouse",
        raw: [],
      },
    };

    const spouse = {
      name: "",
      nric: "",
      email: "",
      phone: "",
      employment: "",
    };

    return {
      store,
      profileDetailsValidator,
      profile,
      employments,
      educations,
      employmentStatuses,
      spouse,
      showSpouseDetails: false,
    };
  },
  async mounted() {
    let tempProfile = this.theProfile;
    if (!tempProfile.user_raw_profile) {
      tempProfile.user_raw_profile = this.profile.user_raw_profile;
    }
    this.profile = tempProfile;
    if (this.profile.gender == null || this.profile.gender == "") {
      this.profile.gender = this.getGender(this.profile.nric);
    }
    if (this.profile.birthdate == null || this.profile.birthdate == "") {
      this.profile.birthdate = this.getBirthdate(this.profile.nric);
    }

    this.getCities(this.profile.user_raw_profile.raw.state);
  },
  methods: {
    moment: function () {
      return moment();
    },
    getHumanDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    getBirthdate(nric) {
      let birthdate = "";
      if (nric.length == 12) {
        let year = nric.substring(0, 2);
        let month = nric.substring(2, 4);
        let day = nric.substring(4, 6);

        if (parseInt(year) > 40 && parseInt(year) < 99) {
          year = "19" + year;
        } else {
          year = "20" + year;
        }

        birthdate = year + "-" + month + "-" + day;
      }
      return birthdate;
    },
    getGender(nric) {
      let gender = "";
      if (nric.length == 12) {
        let genNo = nric.substring(10, 12);

        if (genNo % 2 == 0) {
          gender = "Female";
        } else {
          gender = "Male";
        }
      }
      return gender;
    },
    getCities(state) {
      this.$emit("getCities", { state: state, type: "personal" });
    },
    getStateId(state_name) {
      for (let index of Object.keys(this.states)) {
        if (state_name == this.states[index].ParameterValue) {
          return this.states[index].id;
        }
      }
    },
    onStateChange() {
      this.getCities(this.profile.user_raw_profile.raw.state);
    },
    updateProfile() {
      this.profile.user_raw_profile.raw.name = this.profile.name;
      this.profile.user_raw_profile.raw.nric = this.profile.nric;
      this.profile.user_raw_profile.raw.email = this.profile.email;
      this.profile.user_raw_profile.raw.phone = this.profile.phone;
      this.profile.user_raw_profile.raw.gender = this.profile.gender;
      this.profile.user_raw_profile.raw.birthdate = this.profile.birthdate;

      this.$emit("onSaveFactFindingSheet", "Personal");
    },
  },
});
