
import { defineComponent, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defaultEconomicIndexes } from "../../../utils/FactFindingSheetUtils";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  name: "customer-financial-statements-cash-flow",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      economic_indexes: cloneDeep(defaultEconomicIndexes),
      showDoc: true,
    };
  },
  methods: {
    onResetEconomicIndex() {
      this.showDoc = false;
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Economic Index details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.economic_indexes = cloneDeep(defaultEconomicIndexes);
          this.showDoc = true;
          Swal.fire({
            title: "Reset!",
            text: "Economic Index details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },

    onSaveEconomicIndex() {
      this.$emit("onSaveFactFindingSheet", "Economic Index", this.economic_indexes);
    },
  },
  async mounted() {
    // this.init();
  },
});
