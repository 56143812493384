
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n/index";
import { defaultNetWorthStatement } from "../../../utils/FactFindingSheetUtils";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  name: "customer-financial-statements-networth",
  props: ["theFFS"],
  components: {
    ElCurrencyInputNoSymbol,
    Form,
    Field,
  },
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    return { nFormat };
  },
  data() {
    return {
      statements: cloneDeep(defaultNetWorthStatement),
      isDataLoaded: false,
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.theFFS.netWorth.statement) {
        this.statements = this.theFFS.netWorth.statement;
      }
      this.isDataLoaded = true;
    },

    calculateAssets(assets, ind) {
      this.statements.assets.child[assets].child[ind].combined =
        this.statements.assets.child[assets].child[ind].you +
        this.statements.assets.child[assets].child[ind].spouse;

      this.statements.assets.child[assets].you = 0;
      this.statements.assets.child[assets].spouse = 0;
      this.statements.assets.child[assets].combined = 0;
      for (let ChildAsset of Object.keys(
        this.statements.assets.child[assets].child
      )) {
        this.statements.assets.child[
          assets
        ].you += this.statements.assets.child[assets].child[ChildAsset].you;
        console.log(this.statements.assets.child[assets].child[ChildAsset].you);
        this.statements.assets.child[
          assets
        ].spouse += this.statements.assets.child[assets].child[
          ChildAsset
        ].spouse;
        this.statements.assets.child[
          assets
        ].combined += this.statements.assets.child[assets].child[
          ChildAsset
        ].combined;
      }

      this.statements.assets.you =
        this.statements.assets.child.cashCashEquivalent.you +
        this.statements.assets.child.investmentAssets.you +
        this.statements.assets.child.personalUsedAssets.you;

      this.statements.assets.spouse =
        this.statements.assets.child.cashCashEquivalent.spouse +
        this.statements.assets.child.investmentAssets.spouse +
        this.statements.assets.child.personalUsedAssets.spouse;

      this.statements.assets.combined =
        this.statements.assets.child.cashCashEquivalent.combined +
        this.statements.assets.child.investmentAssets.combined +
        this.statements.assets.child.personalUsedAssets.combined;

        this.calculateNetworth();
    },

    calculateLblts(ind) {
      this.statements.lblts.child.currLblts.child[ind].combined =
        this.statements.lblts.child.currLblts.child[ind].you +
        this.statements.lblts.child.currLblts.child[ind].spouse;

      this.statements.lblts.child.currLblts.you = 0;
      this.statements.lblts.child.currLblts.spouse = 0;
      this.statements.lblts.child.currLblts.combined = 0;
      for (let ChildLblt of Object.keys(
        this.statements.lblts.child.currLblts.child
      )) {
        this.statements.lblts.child.currLblts.you += this.statements.lblts.child.currLblts.child[
          ChildLblt
        ].you;
        console.log(this.statements.lblts.child.currLblts.child[ChildLblt].you);
        this.statements.lblts.child.currLblts.spouse += this.statements.lblts.child.currLblts.child[
          ChildLblt
        ].spouse;
        this.statements.lblts.child.currLblts.combined += this.statements.lblts.child.currLblts.child[
          ChildLblt
        ].combined;
      }
      this.calculateTotalLblts();
    },
    calculateLbltsLong(ind) {
      this.statements.lblts.child.longTermLblts.child[ind].combined =
        this.statements.lblts.child.longTermLblts.child[ind].you +
        this.statements.lblts.child.longTermLblts.child[ind].spouse;

      this.statements.lblts.child.longTermLblts.you = 0;
      this.statements.lblts.child.longTermLblts.spouse = 0;
      this.statements.lblts.child.longTermLblts.combined = 0;
      for (let ChildLblt of Object.keys(
        this.statements.lblts.child.longTermLblts.child
      )) {
        this.statements.lblts.child.longTermLblts.you += this.statements.lblts.child.longTermLblts.child[
          ChildLblt
        ].you;
        console.log(
          this.statements.lblts.child.longTermLblts.child[ChildLblt].you
        );
        this.statements.lblts.child.longTermLblts.spouse += this.statements.lblts.child.longTermLblts.child[
          ChildLblt
        ].spouse;
        this.statements.lblts.child.longTermLblts.combined += this.statements.lblts.child.longTermLblts.child[
          ChildLblt
        ].combined;
      }
      this.calculateTotalLblts();
    },
    calculateTotalLblts() {
      this.statements.lblts.you =
        this.statements.lblts.child.currLblts.you +
        this.statements.lblts.child.longTermLblts.you;

      this.statements.lblts.spouse =
        this.statements.lblts.child.currLblts.spouse +
        this.statements.lblts.child.longTermLblts.spouse;

      this.statements.lblts.combined =
        this.statements.lblts.child.currLblts.combined +
        this.statements.lblts.child.longTermLblts.combined;

      this.calculateNetworth();
    },
    calculateNetworth(){
      this.statements.networth.you = this.statements.assets.you - this.statements.lblts.you;
      this.statements.networth.spouse = this.statements.assets.spouse - this.statements.lblts.spouse;
      this.statements.networth.combined = this.statements.assets.combined - this.statements.lblts.combined;
    },
    onResetNetWorthStatement() {
      this.isDataLoaded = false;
      Swal.fire({
        title: "Are you sure?",
        text:
          "You will not be able to recover this Net Worth Statement details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        this.isDataLoaded = true;
        if (result.value) {
          this.statements = cloneDeep(defaultNetWorthStatement);
          Swal.fire({
            title: "Reset!",
            text: "Net Worth Statement details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },

    onSaveNetWorthStatement() {
      this.$emit(
        "onSaveFactFindingSheet",
        "Net Worth Statement",
        this.statements
      );
    },
  },
});
