
import { defineComponent, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "customer-financial-statements-cash-flow",
  props:['theFFS'],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const net_worth_questionnaire = {
      q1: { question: "What do you try to avoid?", answer: "" },
      q2: {
        question: "Do you want to continue your education? In what way?",
        answer: "",
      },
      q3: {
        question: "Would you consider changing careers? To what?",
        answer: "",
      },
      q4: { question: "What are your hobbies?", answer: "" },
      q5: { question: "Do you do volunteer work?", answer: "" },
      q6: { question: "How do you meet new people?", answer: "" },
      q7: {
        question: "Do you consider yourself a saver or a spender?",
        answer: "",
      },
    };
    return {
      net_worth_questionnaire,
      isDataLoaded: false,
    };
  },
  methods: {
    init() {
      if (this.theFFS.netWorth.questionnaire) {
        this.net_worth_questionnaire = this.theFFS.netWorth.questionnaire;
      }
      this.isDataLoaded = true;
    },
    onSaveNetWorthQuestionnaire() {
      this.$emit("onSaveFactFindingSheet", "Net Worth Questionnaire",  this.net_worth_questionnaire);
    },

    onResetNetWorthQuestionnaire() {
      Swal.fire({
        title: "Are you sure?",
        text:
          "You will not be able to recover this Net Worth Questionnaire details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.net_worth_questionnaire.q1.answer = "";
          this.net_worth_questionnaire.q2.answer = "";
          this.net_worth_questionnaire.q3.answer = "";
          this.net_worth_questionnaire.q4.answer = "";
          this.net_worth_questionnaire.q5.answer = "";
          this.net_worth_questionnaire.q6.answer = "";
          this.net_worth_questionnaire.q7.answer = "";

          Swal.fire({
            title: "Reset!",
            text: "Net Worth Questionnaire details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
  },
  async mounted() {
    this.init();
  },
});
