
import { computed, defineComponent, onMounted, ref, useSlots } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import {
  employmentOptions,
  maritalStatusOptions,
  educationOptions,
} from "../../../../data/FASLookups";
import { spouseValidator } from "../../../utils/FinancialPlanningValidatorUtils";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Modal } from "bootstrap";

interface HirePurchase {
  loanAmount: string;
  loanPeriod: number;
  interestRate: number;
}

export default defineComponent({
  props: [
    "theProfile",
    "isMuslim",
    "states",
    "spCities",
    "residential_statuses",
    "races",
  ],
  components: {
    ErrorMessage,
    Field,
    Form,
    // ElCurrencyInput,
  },
  data() {
    const spouse = {
      address1: "",
      address2: "",
      address3: "",
      alt_phone: "",
      birthdate: "",
      city: "",
      education: "",
      email: "",
      employment: "",
      employment_address: "",
      employment_name: "",
      gender: "",
      have_living_trust_setup: false,
      have_power_of_attorney: false,
      have_testamentary_trust_setup: false,
      id: null,
      is_good_health: false,
      is_muslim: false,
      is_smoker: false,
      life_expectancy: "",
      marital_status: "",
      medical_hereditary: "",
      name: "",
      name_of_attorney: "",
      no_of_dependent: "",
      nric: "",
      occupation: "",
      phone: "",
      postcode: "",
      race: "",
      residential_status: "",
      retirement_age: "",
      retirement_date: "",
      state: "",
      will_held_by: "",
      will_last_updated: "",
    };

    const profile = {
      id: null,
      user_raw_profile: {
        id: null,
        user_id: null,
        type: "profile",
        raw: {
          marital_status: "",
        },
      },
      user_raw_spouses: {
        id: null,
        user_id: null,
        type: "spouse",
        raw: [],
      },
    };

    const actions = "addSpouse";

    return {
      profile,
      actions,
      addSpouseValidator: spouseValidator,
      maritalStatuses: maritalStatusOptions,
      educations: educationOptions,
      employments: employmentOptions,
      spouse,
      showSpouseDetails: false,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    const addSpouseModal = ref(null);
    const closeModal = () => Modal.getInstance(addSpouseModal.value)?.hide();

    return {
      addSpouseModal,
      closeModal,
    };
  },
  methods: {
    init() {
      console.log("init");
      // console.log(this.factFindingSheet);
    },
    onShowAddSpouseModal() {
      this.actions = "addSpouse";
      this.spouse = {
        address1: "",
        address2: "",
        address3: "",
        alt_phone: "",
        birthdate: "",
        city: "",
        education: "",
        email: "",
        employment: "",
        employment_address: "",
        employment_name: "",
        gender: "",
        have_living_trust_setup: false,
        have_power_of_attorney: false,
        have_testamentary_trust_setup: false,
        id: null,
        is_good_health: false,
        is_muslim: false,
        is_smoker: false,
        life_expectancy: "",
        marital_status: "",
        medical_hereditary: "",
        name: "",
        name_of_attorney: "",
        no_of_dependent: "",
        nric: "",
        occupation: "",
        phone: "",
        postcode: "",
        race: "",
        residential_status: "",
        retirement_age: "",
        retirement_date: "",
        state: "",
        will_held_by: "",
        will_last_updated: "",
      };
      // this.profile.user_raw_spouses.raw.push(this.spouse);
      // this.closeModal();
    },
    onEditSpouse(index) {
      this.actions = "editSpouse";
      this.spouse = this.profile.user_raw_spouses.raw[index];
    },
    onUpdateSpouse() {
      if (this.actions == "addSpouse") {
        this.profile.user_raw_spouses.raw.push(this.spouse);
      }

      this.closeModal();
      return;
    },
    onRemoveSpouse(index) {
      this.profile.user_raw_spouses.raw.splice(index, 1);
    },
    onMaritalStatusChange() {
      if (this.profile.user_raw_profile.raw.marital_status == "Married") {
        this.showSpouseDetails = true;
      } else {
        this.profile.user_raw_spouses.raw = [];
        this.showSpouseDetails = false;
      }
    },
    onStateChange() {
      this.$emit("getCities", {
        state: this.spouse.state,
        type: "spouse",
      });
    },
    onSaveSpouses() {
      if (
        this.profile.user_raw_profile.raw.marital_status == "Married" &&
        this.profile.user_raw_spouses.raw.length == 0
      ) {
        Swal.fire({
          title: "Error!",
          text: "Please add at least one spouse.",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      this.$emit("onSaveFactFindingSheet", "Spouses");
    },
    onResetSpouses() {
      console.log("onResetSpouses");
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this spouse details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          for (
            let index = 0;
            index < this.profile.user_raw_spouses.raw.length;
            index++
          ) {
            this.profile.user_raw_spouses.raw.splice(
              index,
              this.profile.user_raw_spouses.raw.length
            );
          }
          Swal.fire({
            title: "Reset!",
            text: "Spouse details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
  },

  async mounted() {
    let tempProfile = this.theProfile;
    if (!tempProfile.user_raw_profile) {
      tempProfile.user_raw_profile = this.profile.user_raw_profile;
    }
    if (!tempProfile.user_raw_spouses) {
      tempProfile.user_raw_spouses = this.profile.user_raw_spouses;
    } else {
      if (tempProfile.user_raw_profile.raw.marital_status == "Married") {
        this.showSpouseDetails = true;
      }
    }
    this.profile = tempProfile;

    this.init();
  },
});
