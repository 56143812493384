
import { defineComponent, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "customer-financial-statements-cash-flow",
  props: [""],
  components: {
    Form,
    Field,
  },
  data() {
    const others = {
      question_1: {
        question:
          "Do you, your spouse or children expect to receive an inheritance in the near future?",
        answer: false,
        subquestion: {
          question: "If yes, please provide details",
          answer: "",
        },
      },
      question_2: {
        question:
          "Other information for consideration (e.g. expected changes to your income or debt level, proposed sales of assets, capital gains tax liability, etc.)",
        answer: "",
      },
    };
    return {
      others,
    };
  },
  methods:{
    onResetOthers() {
      Swal.fire({
        title: "Are you sure?",
        text:
          "You will not be able to recover this Others details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.others.question_1.answer = false;
          this.others.question_1.subquestion.answer = "";
          this.others.question_2.answer = "";

          Swal.fire({
            title: "Reset!",
            text: "Others details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },

    onSaveOthers() {
      this.$emit("onSaveFactFindingSheet", "Others", this.others);
    },
  },
  async mounted() {
    // this.init();
  },
});
