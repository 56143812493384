
import { defineComponent, onMounted } from "vue";
import { Form } from "vee-validate";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n/index";
import { defaultCashFlowStatement } from "../../../utils/FactFindingSheetUtils";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  name: "customer-financial-statements-cash-flow",
  props: ["theFFS"],
  components: {
    ElCurrencyInputNoSymbol,
    Form,
  },
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    return { nFormat };
  },
  data() {
    return {
      statements: cloneDeep(defaultCashFlowStatement),
      isCashFlowLoaded: false,
    };
  },
  methods: {
    init() {
      if (this.theFFS.cashFlow.statement) {
        this.statements = this.theFFS.cashFlow.statement;
      }
      this.isCashFlowLoaded = true;
    },

    onChangeInFlowAmount(value) {
      this.statements.cashInFlow.child[value].combined =
        this.statements.cashInFlow.child[value].you +
        this.statements.cashInFlow.child[value].spouse;
      this.statements.cashInFlow.child[value].monthly =
        this.statements.cashInFlow.child[value].combined / 12;

      this.calculateCashInFlow();
    },

    calculateCashInFlow() {
      //EPF From Employer
      const grossIncomeYou =
        this.statements.cashInFlow.child.grossAnnualEmploymentIncome.you +
        this.statements.cashInFlow.child.grossAnnualEmploymentBonus.you;
      if (grossIncomeYou / 12 >= 5000) {
        this.statements.cashInFlow.child.epfFromEmployer.you =
          grossIncomeYou * 0.12;
      } else {
        this.statements.cashInFlow.child.epfFromEmployer.you =
          grossIncomeYou * 0.13;
      }

      this.statements.cashInFlow.child.lessEpfOfEmployee.you =
        grossIncomeYou * 0.11;

      this.statements.cashInFlow.child.lessEpfOfEmployer.you = this.statements.cashInFlow.child.epfFromEmployer.you;

      const grossIncomeSpouse =
        this.statements.cashInFlow.child.grossAnnualEmploymentIncome.spouse +
        this.statements.cashInFlow.child.grossAnnualEmploymentBonus.spouse;
      if (grossIncomeSpouse / 12 >= 5000) {
        this.statements.cashInFlow.child.epfFromEmployer.spouse =
          grossIncomeSpouse * 0.12;
      } else {
        this.statements.cashInFlow.child.epfFromEmployer.spouse =
          grossIncomeSpouse * 0.13;
      }

      this.statements.cashInFlow.child.lessEpfOfEmployee.spouse =
        grossIncomeSpouse * 0.11;

      this.statements.cashInFlow.child.lessEpfOfEmployer.spouse = this.statements.cashInFlow.child.epfFromEmployer.spouse;

      this.statements.cashInFlow.child.epfFromEmployer.combined =
        this.statements.cashInFlow.child.epfFromEmployer.you +
        this.statements.cashInFlow.child.epfFromEmployer.spouse;

      this.statements.cashInFlow.child.epfFromEmployer.monthly =
        this.statements.cashInFlow.child.epfFromEmployer.combined / 12;

      this.statements.cashInFlow.child.lessEpfOfEmployee.combined =
        this.statements.cashInFlow.child.lessEpfOfEmployee.you +
        this.statements.cashInFlow.child.lessEpfOfEmployee.spouse;

      this.statements.cashInFlow.child.lessEpfOfEmployee.monthly =
        this.statements.cashInFlow.child.lessEpfOfEmployee.combined / 12;

      this.statements.cashInFlow.child.lessEpfOfEmployer.combined =
        this.statements.cashInFlow.child.lessEpfOfEmployer.you +
        this.statements.cashInFlow.child.lessEpfOfEmployer.spouse;

      this.statements.cashInFlow.child.lessEpfOfEmployer.monthly =
        this.statements.cashInFlow.child.lessEpfOfEmployer.combined / 12;


      //Sub Total
      this.statements.cashInFlow.child.subTotal.you =
        this.statements.cashInFlow.child.grossAnnualEmploymentIncome.you +
        this.statements.cashInFlow.child.grossAnnualEmploymentBonus.you +
        this.statements.cashInFlow.child.epfFromEmployer.you;

      this.statements.cashInFlow.child.subTotal.spouse =
        this.statements.cashInFlow.child.grossAnnualEmploymentIncome.spouse +
        this.statements.cashInFlow.child.grossAnnualEmploymentBonus.spouse +
        this.statements.cashInFlow.child.epfFromEmployer.spouse;

      this.statements.cashInFlow.child.subTotal.combined =
        this.statements.cashInFlow.child.subTotal.you +
        this.statements.cashInFlow.child.subTotal.spouse;

      this.statements.cashInFlow.child.subTotal.monthly =
        this.statements.cashInFlow.child.subTotal.combined / 12;

      //Net Annual Income And Bonus
      this.statements.cashInFlow.child.netAnnualIncomeAndBonus.you =
        this.statements.cashInFlow.child.subTotal.you -
        this.statements.cashInFlow.child.lessEpfOfEmployee.you -
        this.statements.cashInFlow.child.lessEpfOfEmployer.you;

      this.statements.cashInFlow.child.netAnnualIncomeAndBonus.spouse =
        this.statements.cashInFlow.child.subTotal.spouse -
        this.statements.cashInFlow.child.lessEpfOfEmployee.spouse -
        this.statements.cashInFlow.child.lessEpfOfEmployer.spouse;

      this.statements.cashInFlow.child.netAnnualIncomeAndBonus.combined =
        this.statements.cashInFlow.child.netAnnualIncomeAndBonus.you +
        this.statements.cashInFlow.child.netAnnualIncomeAndBonus.spouse;

      this.statements.cashInFlow.child.netAnnualIncomeAndBonus.monthly =
        this.statements.cashInFlow.child.netAnnualIncomeAndBonus.combined / 12;

      //Total Inflow
      this.statements.cashInFlow.you =
        this.statements.cashInFlow.child.netAnnualIncomeAndBonus.you +
        this.statements.cashInFlow.child.returnofInvestment.you +
        this.statements.cashInFlow.child.otherIncome.you;
      this.statements.cashInFlow.monthly = this.statements.cashInFlow.you / 12;

      this.statements.cashInFlow.spouse =
        this.statements.cashInFlow.child.netAnnualIncomeAndBonus.spouse +
        this.statements.cashInFlow.child.returnofInvestment.spouse +
        this.statements.cashInFlow.child.otherIncome.spouse;
      this.statements.cashInFlow.monthly =
        this.statements.cashInFlow.spouse / 12;

      this.statements.cashInFlow.combined =
        this.statements.cashInFlow.you + this.statements.cashInFlow.spouse;

      this.statements.cashInFlow.monthly =
        this.statements.cashInFlow.combined / 12;

      this.statements.netCashFlow.you =
        this.statements.cashInFlow.you - this.statements.cashOutFlow.you;

      this.statements.netCashFlow.monthly =
        this.statements.cashInFlow.combined / 12;

      this.calculateNetCashFlow();
    },

    onChangeOutFlowAmount(value) {
      this.statements.cashOutFlow.child[value].combined =
        this.statements.cashOutFlow.child[value].you +
        this.statements.cashOutFlow.child[value].spouse;
      this.statements.cashOutFlow.child[value].monthly =
        this.statements.cashOutFlow.child[value].combined / 12;

      this.calculateCashOutFlow();
    },

    calculateCashOutFlow() {
      this.statements.cashOutFlow.you = 0;
      this.statements.cashOutFlow.spouse = 0;
      this.statements.cashOutFlow.combined = 0;
      this.statements.cashOutFlow.monthly = 0;

      for (let cashIn of Object.keys(this.statements.cashOutFlow.child)) {
        this.statements.cashOutFlow.you += this.statements.cashOutFlow.child[
          cashIn
        ].you;

        this.statements.cashOutFlow.spouse += this.statements.cashOutFlow.child[
          cashIn
        ].spouse;
      }

      this.statements.cashOutFlow.combined =
        this.statements.cashOutFlow.spouse + this.statements.cashOutFlow.you;

      this.statements.cashOutFlow.monthly =
        this.statements.cashOutFlow.combined / 12;

      this.calculateNetCashFlow();
    },

    calculateNetCashFlow() {
      this.statements.netCashFlow.you =
        this.statements.cashInFlow.you - this.statements.cashOutFlow.you;

      this.statements.netCashFlow.spouse =
        this.statements.cashInFlow.spouse - this.statements.cashOutFlow.spouse;

      this.statements.netCashFlow.combined =
        this.statements.netCashFlow.you + this.statements.netCashFlow.spouse;

      this.statements.netCashFlow.monthly =
        this.statements.netCashFlow.combined / 12;
    },

    onResetCashFlowStatement() {
      this.isCashFlowLoaded = false;
      Swal.fire({
        title: "Are you sure?",
        text:
          "You will not be able to recover this Cash Flow Statement details!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then((result) => {
        if (result.value) {
          this.statements = cloneDeep(defaultCashFlowStatement);
          Swal.fire({
            title: "Reset!",
            text: "Cash Flow Statement details has been reset.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          this.isCashFlowLoaded = true;
        }
      });
    },

    onSaveCashFlowStatement() {
      this.$emit(
        "onSaveFactFindingSheet",
        "Cash Flow Statement",
        this.statements
      );
    },
  },
  async mounted() {
    this.init();
  },
});
